import classNames from 'classnames';
import { useFormikContext } from 'formik';
import {
  isUserEmployee,
  isUserOperator,
  isUserIndividual,
  isUserManager,
} from 'helpers/jwt_helper';
import { useMatchMedia } from 'hooks/useMatchMedia';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  border: 0;

  i {
    margin-right: ${({ theme }) => theme.spacing.s};
    font-size: 18px;
  }
`;

const StyledClose = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.mainText} !important;
  background-color: ${({ theme }) => theme.colors.transparent};

  &:hover {
    color: ${({ theme }) => theme.colors.mainText} !important;
    background-color: ${({ theme }) => theme.colors.transparent};
  }
`;

export const StyledSave = styled(StyledButton)`
  padding: 12px 44px;
  background-color: ${({ theme }) => theme.colors.gray200}!important;
  color: ${({ theme }) => theme.colors.mainText} !important;
`;

const StyledCreate = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.green500}!important;
  padding: 12px 56px;
  color: ${({ theme }) => theme.colors.white}!important;
`;

const StyledDiv = styled.div`
  margin-top: -20px;
`;

export const OrderControlButtons = ({
  isDisabledButton,
  handleSaveOrder,
  handleSaveAndContinueOrder,
  isDisabledConfirm,
  openConfirmModal,
  isSubmitting,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { id } = useParams();
  const formik = useFormikContext();
  const { isMobile, isTablet } = useMatchMedia();

  const isDisabledFields = useMemo(
    () => !formik.values.orderDetail?.isEditable,
    [formik.values.orderDetail?.isEditable],
  );

  const getVisibleConfirmOrder = () => {
    if (
      isUserIndividual &&
      formik.values.orderDetail?.isEditable &&
      !formik.dirty
    ) {
      return true;
    }

    if (
      (isUserEmployee || isUserManager) &&
      id &&
      formik.values.orderDetail?.isEditable &&
      !formik.dirty
    ) {
      return true;
    }

    return false;
  };

  return (
    <StyledDiv
      className={classNames(
        isMobile
          ? 'd-flex flex-wrap mt-1 justify-content-start'
          : 'd-flex w-100 justify-content-end align-items-center',
      )}
    >
      <StyledClose
        onClick={() => push('/orders')}
        className={classNames(
          'btn me-3',
          (isMobile || isTablet) && 'p-2 d-flex px-0',
        )}
      >
        <i className="bx bx-x" />

        <span>{t('common.close')}</span>
      </StyledClose>

      {!isDisabledFields && (
        <>
          <StyledSave
            disabled={isDisabledButton}
            onClick={handleSaveOrder}
            className={classNames(
              'me-3 d-flex',
              (isMobile || isTablet) && 'p-2 d-flex',
            )}
          >
            {isSubmitting ? (
              <>
                <Spinner
                  className="me-2"
                  style={{ padding: '0.75rem' }}
                  color="light"
                  size="sm"
                  type="grow"
                >
                  Loading...
                </Spinner>
              </>
            ) : (
              <>
                <span>{t('common.save')}</span>
              </>
            )}
          </StyledSave>

          {isUserOperator && (
            <StyledCreate
              disabled={isDisabledButton}
              className={(isMobile || isTablet) && 'me-3 d-flex p-2 d-flex'}
              onClick={handleSaveAndContinueOrder}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    className="me-2"
                    style={{ padding: '0.75rem' }}
                    color="light"
                    size="sm"
                    type="grow"
                  >
                    Loading...
                  </Spinner>
                </>
              ) : (
                <>
                  <span>{t(id ? 'order.saveAndGo' : 'common.create')}</span>
                </>
              )}
            </StyledCreate>
          )}
        </>
      )}

      {getVisibleConfirmOrder() && (
        <StyledCreate
          className={(isMobile || isTablet) && 'me-3 d-flex p-2 d-flex px-3'}
          disabled={isDisabledConfirm}
          onClick={openConfirmModal}
        >
          <i className="bx bx-check" />

          <span>{t('auth.confirm')}</span>
        </StyledCreate>
      )}
    </StyledDiv>
  );
};
