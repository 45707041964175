import React, { useEffect, useMemo, useState } from 'react';
import { ORDERS_BREADCRUMBS } from 'common/data/beadcrumbs';
import { OrderControlButtons } from 'components/Order/OrderControlButtons';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { OrderNavbar } from 'components/Order/Card/OrderNavbar';
import { FormikProvider, useFormik } from 'formik';
import { SelectClientCard } from 'components/Order/Card/SelectClientCard';
import { SelectOrderTypeCard } from 'components/Order/Card/SelectOrderTypeCard';
import { OrderApplicants } from 'components/Order/Card/OrderApplicants';
import { OrderDelivery } from 'components/Order/Card/OrderDelivery';
import { InvoicesCard } from 'components/Order/Card/InvoicesCard';
import { useDispatch, useSelector } from 'react-redux';

import {
  createOrder,
  fetchOrder,
  updateOrder as updateOrderAction,
} from 'store/order/actions';
// import { ORDER_VALIDATOR } from 'forms/validators';
import { converFromApi, converOrderToApi } from 'helpers/converters';
import { useUnload } from 'hooks/useUnload';
import { useOrderAlert } from 'hooks/useOrderAlert';
import { typeColor } from 'helpers/styles/typeColor';
import {
  isUserEmployee,
  isUserManager,
  isUserIndividual,
  isUserOperator,
} from 'helpers/jwt_helper';
import { LayoutDetailPages } from 'hoc/LayoutDetailPages';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { useModal } from 'hooks/useModal';
import { confirmOrder, checkOrder } from 'helpers/api-requests/manager';
import { updateOrder } from 'helpers/api-requests/admin';
import { showToastError, showToastSuccess } from 'helpers/utils/toast';
import { Price } from 'components/Order/Card/OrderPrice';
import { flatten } from 'lodash';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { StyledButtonGray } from 'components/Common';
import {
  assignToMeRequest,
  completeOrderInvitationRequest,
} from 'helpers/api-requests/admin';
import { StyledInput } from 'components/Common/FormikInput';
import { getOrderTypeRequest } from 'helpers/api-requests/admin';

const initialData = {
  client: {
    clientType: null,
    client: null,
    country: null,
    internalNumber: null,
    accountingUnit: null,
  },
  applicants: [],
  orderDetail: {
    isEditable: true,
    orderType: null,
  },
};

const Order = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t, i18n } = useTranslation();
  const { openAlert } = useOrderAlert();

  const { id } = useParams();
  const { order } = useSelector(state => state.order);
  const { isOpen, handleCloseModal, handleOpenModal } = useModal();
  const [isOpenAssignToMeModal, setIsOpenAssignToMeModal] = useState(false);
  const { isMobile, isTablet } = useMatchMedia();
  const [useFieldPreviousVisa, setUseFieldPreviousVisa] = useState(null);
  const [useFieldPreviousUssr, setUseFieldPreviousUssr] = useState(null);
  const [isOpenCompleteOrderInvitation, setIsOpenCompleteOrderInvitation] =
    useState(false);
  const [completeOrderInvitationMsg, setCompleteOrderInvitationMsg] =
    useState('');

  const onCompleteOrderInvitationMsgChange = e => {
    setCompleteOrderInvitationMsg(e.target.value);
  };

  const handleAdditionalFields = (FieldPreviousVisa, FieldPreviousUssr) => {
    setUseFieldPreviousVisa(FieldPreviousVisa);
    setUseFieldPreviousUssr(FieldPreviousUssr);
  };

  const handleSubmitOrder = values => {
    if (id) {
      dispatch(
        updateOrderAction({
          order: converOrderToApi(
            values,
            orderTypeFieldsSettings.enableApplicantPassport,
          ),
          id,
          push,
          redirectPath: values.isRedirect && values.redirectPath,
        }),
      );
    } else {
      dispatch(
        createOrder({
          order: converOrderToApi(
            values,
            orderTypeFieldsSettings.enableApplicantPassport,
          ),
          push,
          redirectPath: values.isRedirect && values.redirectPath,
        }),
      );
    }
  };

  const handleAssignToMeReq = () => {
    assignToMeRequest(order.order.id)
      .then(response => {
        if (response.success) {
          showToastSuccess(response.message);
          dispatch(fetchOrder({ id }));
        } else {
          showToastError(response.message);
        }
      })
      .catch(err => showToastError(err));

    handleCloseAssignToMeModal();
  };

  const handleCloseAssignToMeModal = () => {
    setIsOpenAssignToMeModal(false);
  };

  const handleCompleteOrderInvitationReq = () => {
    completeOrderInvitationRequest(order.order.id, {
      message: completeOrderInvitationMsg,
    })
      .then(response => {
        if (response.success) {
          showToastSuccess(response.message);
        } else {
          showToastError(response.message);
        }
      })
      .catch(err => showToastError(err));

    handleCloseCompleteOrderInvitation();
  };

  const handleCloseCompleteOrderInvitation = () => {
    setCompleteOrderInvitationMsg('');
    setIsOpenCompleteOrderInvitation(false);
  };

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    validateOnChange: false,
    validate: validate,
    // validationSchema: ORDER_VALIDATOR,
    onSubmit: handleSubmitOrder,
  });

  // const validateDescription = async applicants => {
  //   let error = {
  //     applicants: Array.apply(null, Array(applicants.length))
  //   };

  //   applicants.map(
  //     (applicant, index) =>  {
  //       // openAlert({
  //       //   message: `Check description for applicant ${applicantDetail.firstName} ${applicantDetail.lastName}`,
  //       //   type: typeColor.danger,
  //       // });

  //       if (!applicant.description) {
  //         error.applicants[index] = {
  //           description: i18n.t('validation.required')
  //         };
  //       }

  //     }
  //   );

  //   if (error.applicants.some(a => a?.description)){
  //     return error;
  //   }
  // };

  async function validate(values) {
    const applicantsNullArray = [];

    for (var i = 0; i < values.applicants.length; i++) {
      applicantsNullArray.push({});
    }

    let error = {
      applicants: applicantsNullArray,
    };

    values.applicants.map((applicant, index) => {
      if (orderTypeFieldsSettings.enableApplicantPassport) {
        if (!applicant.applicantDetail?.passport?.number) {
          showToastError({
            number: `${applicant.applicantDetail.firstName} ${applicant.applicantDetail.lastName} requires a valid passport number`,
          });
        }
      }

      if (orderTypeFieldsSettings.enableApplicantPassport) {
        if (!applicant.applicantDetail?.passport?.startDate) {
          showToastError({
            number: `${applicant.applicantDetail.firstName} ${applicant.applicantDetail.lastName} requires a avalid passport start date date`,
          });
        }
      }

      if (orderTypeFieldsSettings.enableApplicantPassport) {
        if (!applicant.applicantDetail?.passport?.expirationDate) {
          showToastError({
            number: `${applicant.applicantDetail.firstName} ${applicant.applicantDetail.lastName} requires a avalid passport expiration date`,
          });
        }
      }

      // Validate description
      if (orderTypeFieldsSettings.enableDescription) {
        // Order Description validation

        if (!applicant.description) {
          error.applicants[index].description = i18n.t('validation.required');
        }
      }

      if (orderTypeFieldsSettings.enableVisaType) {
        // Visa Type validation

        if (!applicant.visaType) {
          error.applicants[index].visaType = i18n.t('validation.required');
        }

        // Visa Duration validation

        if (!applicant.visaDuration) {
          error.applicants[index].visaDuration = i18n.t('validation.required');
        }
      }

      // VS Urgency Validation

      if (!applicant.visaUrgency) {
        error.applicants[index].visaUrgency = i18n.t('validation.required');
      }

      // UPS Validation

      if (applicant.upsLabel === 'yes') {
        if (!applicant.upsName) {
          error.applicants[index].upsName = i18n.t('validation.required');
        }
        if (!applicant.upsAddressLineFirst) {
          error.applicants[index].upsAddressLineFirst = i18n.t(
            'validation.required',
          );
        }
        if (!applicant.upsCity) {
          error.applicants[index].upsCity = i18n.t('validation.required');
        }
        if (!applicant.upsPostalCode) {
          error.applicants[index].upsPostalCode = i18n.t('validation.required');
        }
        if (!applicant.upsCountry) {
          error.applicants[index].upsCountry = i18n.t('validation.required');
        }
      }

      // Delivery to address validation
      if (isUserOperator && !applicant.deliveryMethod) {
        error.applicants[index].deliveryMethod = i18n.t('validation.required');
      }

      if (applicant?.deliveryMethod === 'delivery_method_to_address') {
        error.applicants[index].address = {};

        if (!applicant.address?.deliveryName) {
          error.applicants[index].address.deliveryName = i18n.t(
            'validation.required',
          );
        }

        if (!applicant.address?.addressLineFirst) {
          error.applicants[index].address.addressLineFirst = i18n.t(
            'validation.required',
          );
        }

        if (!applicant.address?.city) {
          error.applicants[index].address.city = i18n.t('validation.required');
        }

        if (!applicant.address?.postalCode) {
          error.applicants[index].address.postalCode = i18n.t(
            'validation.required',
          );
        }

        if (!applicant.address?.country) {
          error.applicants[index].address.country = i18n.t(
            'validation.required',
          );
        }
        if (_.isEmpty(error.applicants[index].address)) {
          delete error.applicants[index].address;
        }
      }

      if (
        applicant?.deliveryMethod === 'delivery_method_with_applicant' &&
        !applicant.addressAnotherApplicant
      ) {
        error.applicants[index].addressAnotherApplicant = i18n.t(
          'validation.required',
        );
      }

      if (_.isEmpty(error.applicants[index])) {
        error.applicants[index] = null;
      }
    });

    if (error.applicants?.some(applicant => applicant)) {
      return error;
    }
    return null;
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchOrder({ id }));
    }
  }, []);

  useEffect(() => {
    if (order.order?.id && id && order.order?.id === Number(id)) {
      formik.resetForm({ values: converFromApi(order.order) });
      setUseFieldPreviousVisa(order.order?.country?.field_previous_visa);
      setUseFieldPreviousUssr(order.order?.country?.field_previous_ussr);
    }
  }, [order.order]);

  useEffect(() => {
    if (formik.errors) {
      if (formik.errors.applicants?.some(applicant => applicant)) {
        openAlert({
          message: `notifications.orderVisa`,
          type: typeColor.danger,
        });
      }
    }
  }, [formik.errors]);

  const orderTypeId = formik.values.orderType;
  const [orderTypeFieldsSettings, setOrderTypeFieldSettings] = useState();

  useEffect(() => {
    if (orderTypeId) {
      getOrderTypeRequest(orderTypeId)
        .then(response => {
          if (response.success) {
            setOrderTypeFieldSettings({
              enableForIndividual: response.data.is_individual,
              enableForLegal: response.data.is_legal,
              enableCountry: response.data.country_is_required,
              availableForCountries: response.data.available_for_countries,
              applicantMaxQuantity: response.data.applicant_max_quantity,
              enableApplicantBirthdate:
                response.data.applicant_birthdate_is_required,
              enableApplicantPassport:
                response.data.applicant_passport_is_required,
              enableContactPerson: response.data.contact_person_is_required,
              enableResidency: response.data.residency_is_required,
              enableDescription: response.data.description_is_required,
              enableVisaType: response.data.visa_type_is_required,
              enableVisaUrgency: response.data.visa_urgency_is_required,
              enableDocumentsReceivedBy:
                response.data.documents_received_by_is_required,
              enableDeliveryMethod: response.data.delivery_method_is_required,
              enableDocumentsToConsEstimatedAt:
                response.data.field_documents_to_consulate_estimated_at,
              enableDocumentsToConsActualAt:
                response.data.field_documents_to_consulate_actual_at,
              enableDocumentsFromConsEstimatedAt:
                response.data.field_documents_from_consulate_estimated_at,
              enableDocumentsFromConsActualAt:
                response.data.field_documents_from_consulate_actual_at,
              enableDocumentsToApplicantEstimatedAt:
                response.data.field_documents_to_applicant_estimated_at,
              enableDocumentsToApplicantActualAt:
                response.data.field_documents_to_applicant_actual_at,
              enableFirstDateOfEntry: response.data.first_date_of_entry,
              enableGultigFrom: response.data.gultig_from,
              enableGultigTo: response.data.gultig_to,
            });
          } else {
            showToastError(response.message);
          }
        })
        .catch(err => console.log(err));
    }
  }, [formik.values.orderType]);

  useUnload(event => {
    if (formik.dirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  });

  const title = useMemo(() => {
    if (id && !order.order.number) {
      return 'Order';
    }

    return id
      ? `${t('order.order')} # ${order?.order?.number}`
      : t('order.createOrder');
  }, [order, i18n.language]);

  const isVisibleInvoicesCard = useMemo(() => {
    const defaultIsVisible = id && !isUserManager && !isUserEmployee;

    if (isUserIndividual) {
      const hasInvoices = formik.values.invoices?.filter(
        invoice =>
          invoice.status === 'paid' || invoice.status === 'wait_payment',
      );

      return defaultIsVisible && hasInvoices && hasInvoices.length > 0;
    }
    return defaultIsVisible;
  }, [formik.values.invoices, id]);

  const isDisabledByApplicants = useMemo(
    () =>
      formik.values.applicants.length === 0 ||
      !formik.values.applicants.some(applicant =>
        applicant.applicantDetail.hasOwnProperty('lastName'),
      ),
    [formik.values.applicants],
  );

  const isDisabledButton =
    !formik.dirty || isDisabledByApplicants || order.isSubmitting;

  const allServices = useMemo(
    () =>
      flatten(formik.values.applicants.map(applicant => applicant.services)),
    [formik.values.applicants],
  );

  const validateByConfirm = () => {
    if (isUserEmployee) {
      const employeeHasServices =
        formik.values.applicants[0]?.services?.length > 0;
      if (!employeeHasServices) {
        isMobile || isTablet
          ? showToastError(t(`notifications.orderNoServices`))
          : openAlert({
              message: `notifications.orderNoServices`,
              type: typeColor.danger,
            });
        return false;
      }
      return true;
    }

    const isEveryApplicantHasServices = formik.values.applicants.every(
      applicant => applicant.services?.length > 0,
    );

    // const isEveryApplicantHasDocuments = formik.values.applicants.every(
    //   applicant => applicant.files?.length > 0,
    // );

    if (!isEveryApplicantHasServices) {
      isMobile || isTablet
        ? showToastError(t(`notifications.orderNoServices`))
        : openAlert({
            message: `notifications.orderNoServices`,
            type: typeColor.danger,
          });
      return false;
    }

    // if (!isEveryApplicantHasDocuments) {
    //   isMobile || isTablet
    //     ? showToastError(t(`notifications.orderNoDocuments`))
    //     : openAlert({
    //         message: `notifications.orderNoDocuments`,
    //         type: typeColor.danger,
    //       });
    //   return false;
    // }

    return true;
  };

  const handleCheckOrder = async () => {
    try {
      const response = await checkOrder(id);

      if (response?.success) {
        return true;
      } else {
        isMobile || isTablet
          ? showToastError(response.message)
          : openAlert({
              message: response.message,
              type: typeColor.danger,
            });
        return false;
      }
    } catch (error) {
      console.log('error', error);
    }

    // const response =
    // checkOrder(id).then(response => {

    //   if (response.success) {
    //     return true;
    //   } else {
    //     isMobile || isTablet
    //     ? showToastError(response.message)
    //     : openAlert({
    //         message: response.message,
    //         type: typeColor.danger,
    //       });
    //     return false;
    //   }
    // })
    // .catch(err => showToastError(err));
  };

  const handleConfirmOrder = async () => {
    const orderCheckStatus = await handleCheckOrder();

    handleCloseModal();

    if (!orderCheckStatus) return;

    confirmOrder(id).then(response => {
      if (response.success) {
        const newInvoices = formik.values.invoices?.map(invoice => ({
          ...invoice,
          status: invoice.status === 'draft' ? 'wait_payment' : invoice.status,
        }));

        formik.resetForm({
          values: {
            ...formik.values,
            applicants: formik.values.applicants.map(applicant => ({
              ...applicant,
              statusApplicant: 'new',
            })),
            orderDetail: {
              ...formik.values.orderDetail,
              isEditable: false,
            },
            invoices: newInvoices,
          },
        });

        return;
      }

      showToastError(response.message);
    });
  };

  const handleSaveAndConfirmOrder = async () => {
    if (id) {
      try {
        const errors = await formik.validateForm();

        if (Object.values(errors).length === 0) {
          const response = await updateOrder({
            id,
            order: converOrderToApi(
              formik.values,
              orderTypeFieldsSettings.enableApplicantPassport,
            ),
          });

          if (response.success) {
            formik.resetForm({ values: converFromApi(response.data) });

            if (isUserIndividual) {
              handleConfirmForIndividual();
            } else {
              handleConfirmOrder(false);
            }

            return;
          }

          showToastError(response.message);
          handleCloseModal();
        } else {
          handleCloseModal();
        }
      } catch (error) {
        showToastError(error);
      }
    } else {
      formik.submitForm();

      if (isUserIndividual) {
        handleConfirmForIndividual();
      } else {
        handleConfirmOrder(false);
      }
    }
  };

  const handleSaveOrder = () => {
    formik.handleSubmit();

    // If user individual should redirect to orders page
    // formik.setFieldValue('isRedirect', isUserIndividual);

    if (isUserIndividual) {
      formik.setFieldValue('redirectPath', '/orders');
    }
  };

  const handleSaveAndContinueOrder = () => {
    formik.setFieldValue('redirectPath', '/orders');
    formik.setFieldValue('isRedirect', true);
    formik.handleSubmit();
  };

  const handleConfirmForIndividual = async () => {
    formik.validateForm();

    const orderCheckStatus = await handleCheckOrder();

    handleCloseModal();

    if (!orderCheckStatus) return;

    if (formik.values.invoices?.length > 0) {
      return push(`/invoices/${formik.values.invoices.at().id}`, {
        fromOrder: true,
      });
    }

    formik.setFieldValue('redirectPath', '/invoices/');
    formik.setFieldValue('isRedirect', true);
  };

  const openConfirmModal = () => {
    if (!validateByConfirm()) return;

    handleOpenModal();
  };

  return (
    <FormikProvider value={formik}>
      {!formik.isSubmitting && (
        <Prompt when={formik.dirty} message={t('order.redirectNotification')} />
      )}
      <LayoutDetailPages
        layoutConfig={{
          isScrollableHeader: true,
          extraContent: (
            <OrderControlButtons
              isDisabledConfirm={isDisabledByApplicants}
              isDisabledButton={isDisabledButton}
              handleSaveOrder={handleSaveOrder}
              handleSaveAndContinueOrder={handleSaveAndContinueOrder}
              openConfirmModal={openConfirmModal}
              isSubmitting={order.isSubmitting}
            />
          ),
          title,
          loading: order.isLoading,
          breadcrumbs: ORDERS_BREADCRUMBS,
          extraMobileContent: formik.values.applicants?.[0]?.applicantDetail
            ?.id && <Price allServices={allServices} />,
        }}
        navBarContent={
          <OrderNavbar
            isVisibleInvoices={isVisibleInvoicesCard}
            allServices={allServices}
            applicants={formik.values?.applicants}
            order={order}
            extraContent={
              <>
                {isUserOperator && id && (
                  <StyledButtonGray
                    className="w-100"
                    disabled={!!order.order.assigned_to}
                    onClick={() => setIsOpenAssignToMeModal(true)}
                  >
                    {order.order.assigned_to
                      ? order.order.assigned_to.full_name
                      : t('order.assignToMe')}
                  </StyledButtonGray>
                )}

                {id &&
                  order.order.customer_organization &&
                  (isUserOperator || isUserManager) &&
                  order.order?.customers?.find(
                    customer => customer.status === 'draft',
                  ) && (
                    <StyledButtonGray
                      className="w-100 mt-4"
                      onClick={() => setIsOpenCompleteOrderInvitation(true)}
                    >
                      {t('order.completeOrderInvitation')}
                    </StyledButtonGray>
                  )}
              </>
            }
          />
        }
      >
        <SelectOrderTypeCard />

        {!!orderTypeId &&
          (orderTypeFieldsSettings?.enableCountry ? (
            <SelectClientCard
              change={handleAdditionalFields}
              orderTypeFieldsSettings={orderTypeFieldsSettings}
            />
          ) : !isUserIndividual ? (
            <SelectClientCard
              change={handleAdditionalFields}
              orderTypeFieldsSettings={orderTypeFieldsSettings}
            />
          ) : (
            ''
          ))}

        <OrderApplicants
          useFieldPreviousVisa={useFieldPreviousVisa}
          useFieldPreviousUssr={useFieldPreviousUssr}
          orderTypeFieldsSettings={orderTypeFieldsSettings}
        />

        {formik.values.applicants?.[0]?.applicantDetail.id &&
          id &&
          isUserOperator && <OrderDelivery />}

        {isVisibleInvoicesCard && <InvoicesCard />}

        {isOpenAssignToMeModal && (
          <ModalConfirmDelete
            handleCloseModal={handleCloseAssignToMeModal}
            handleDelete={handleAssignToMeReq}
            textHeader="order.assignToMe"
            textBody="order.assignToMeConfirmText"
            typeButtonConfirm="save"
            isOpenModal={isOpenAssignToMeModal}
            buttonText="order.yes"
          />
        )}

        {isOpen && (
          <ModalConfirmDelete
            handleCloseModal={handleCloseModal}
            handleDelete={handleSaveAndConfirmOrder}
            textHeader="order.orderConfirm"
            textBody="order.orderConfirmText"
            typeButtonConfirm="save"
            isOpenModal={isOpen}
            buttonText="order.yes"
          />
        )}

        {isOpenCompleteOrderInvitation && (
          <ModalConfirmDelete
            handleCloseModal={handleCloseCompleteOrderInvitation}
            handleDelete={handleCompleteOrderInvitationReq}
            textHeader="order.completeOrderInvitation"
            textBody="order.completeOrderInvitationText"
            typeButtonConfirm="save"
            isOpenModal={isOpenCompleteOrderInvitation}
            buttonText="order.yes"
          >
            <StyledInput
              maxLength={1000}
              placeholder={t('order.completeOrderInvitationMsgPlaceholder')}
              value={completeOrderInvitationMsg}
              type="textarea"
              $textarea={true}
              onChange={onCompleteOrderInvitationMsgChange}
            />
          </ModalConfirmDelete>
        )}
      </LayoutDetailPages>
    </FormikProvider>
  );
};

export default Order;
