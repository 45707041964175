import {
  StyledButtonGray,
  StyledColBold,
  StyledField,
  BlueLink,
} from 'components/Common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { DeliveryEdit } from './DeliveryEdit';
import { getShipmentLabelPdf } from 'helpers/downloadDocument';
import styled from 'styled-components';

export const StyledButtonRed = styled(Button)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray600}!important;
  background: ${({ theme }) => theme.colors.gray200}!important;
  border: none;

  i {
    font-size: 16px;
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  &:hover {
    color: white !important;
    background: #fc4503 !important;
  }
`;

export const DeliveryCard = ({
  delivery,
  index,
  handleOpenModal,
  setDeliveries,
  deliveries,
}) => {
  const { t } = useTranslation();
  const [isOpenEditMode, setIsOpenEditMode] = useState(false);
  const [isOpenDeleteNotice, setIsOpenDeleteNotice] = useState(false);

  const handleEditDelivery = () => {
    setIsOpenEditMode(true);
  };

  const handleCloseEditMode = () => {
    setIsOpenEditMode(false);
  };

  const handleOpenDeleteNotice = () => {
    setIsOpenDeleteNotice(true);
  };

  const handleCancelDeleteNotice = () => {
    setIsOpenDeleteNotice(false);
  };

  return (
    <>
      <StyledField
        key={index}
        className="p-3 d-flex mt-3 w-100 flex-lg-nowrap flex-wrap"
      >
        <Col xs={12} lg={7} xl={9}>
          <Label>{`${t('delivery.delivery')} ${index + 1}`}</Label>

          <FormGroup row>
            <Col lg={4} xs={6}>
              <span>{t('delivery.dpsMethod')}</span>
            </Col>

            <StyledColBold className="d-flex flex-column" lg={8} xs={6}>
              <p className="m-0">
                {delivery.providerDetail?.carrier_display ??
                  delivery.providerDetail?.label}
              </p>

              {delivery.methodDetail && (
                <p className="fw-normal m-0 text-muted">
                  {delivery.methodDetail.method_display}
                </p>
              )}
            </StyledColBold>
          </FormGroup>

          {delivery.number && (
            <FormGroup row>
              <Col lg={4} xs={6}>
                <span>{t('delivery.trackingNumber')}</span>
              </Col>

              <StyledColBold xs={6} lg={8}>
                <a
                  className="alert text-info pe-1 px-0"
                  href="https://www.ups.com/ship/history"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bx bx-link-external"></i>
                </a>
                {delivery.number}
                {isOpenDeleteNotice && (
                  <>
                    <div className="alert alert-danger">
                      Please make sure to cancel this delivery on
                      <a
                        className="alert text-info px-2 px-0"
                        href="https://www.ups.com/ship/history"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ups.com/ship/history
                      </a>
                    </div>
                    <StyledButtonGray
                      className="w-30 justify-content-center"
                      // onClick={() => handleOpenModal(delivery.id)}
                      onClick={() => handleCancelDeleteNotice()}
                      // disabled={!delivery.editable}
                    >
                      {/* <i className="bx bxs-trash" /> */}
                      {t('common.cancel')}
                    </StyledButtonGray>

                    <StyledButtonRed
                      className="w-30  mx-2 justify-content-center"
                      // onClick={() => handleOpenModal(delivery.id)}
                      onClick={() => handleOpenModal(delivery.id)}
                      // disabled={!delivery.editable}
                    >
                      <i className="bx bxs-trash" />
                      {t('common.confirmDelete')}
                    </StyledButtonRed>
                  </>
                )}
              </StyledColBold>
            </FormGroup>
          )}

          {delivery.from?.city && (
            <>
              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.from')}</span>
                </Col>

                <Col xs={6} lg={8}>
                  <p>
                    {delivery.from.companyName && (
                      <span className="d-flex ">
                        {delivery.from.companyName}
                      </span>
                    )}
                    {delivery.from.name} - {delivery.from.address} -{' '}
                    {delivery.from.city} {delivery.from.code}{' '}
                    {delivery.from.country_name || delivery.from.countryName}
                  </p>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.to')}</span>
                </Col>

                <Col xs={6} lg={8}>
                  <p>
                    {delivery.to.companyName && (
                      <span className="d-flex ">{delivery.to.companyName}</span>
                    )}
                    {delivery.to.name} - {delivery.to.address} -{' '}
                    {delivery.to.city} {delivery.to.code}{' '}
                    {delivery.to.country_name || delivery.to.countryName}
                  </p>
                </Col>
              </FormGroup>
            </>
          )}

          {delivery.shipmentLabel && (
            <>
              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.shippingLabel')} </span>
                </Col>

                <StyledColBold xs={6} lg={8}>
                  <BlueLink onClick={() => getShipmentLabelPdf(delivery)}>
                    {t('documents.download')}
                  </BlueLink>
                </StyledColBold>
              </FormGroup>
            </>
          )}

          {delivery.shipmentCost && (
            <>
              <FormGroup row>
                <Col lg={4} xs={6}>
                  <span>{t('delivery.total')} </span>
                </Col>

                <Col xs={6} lg={8}>
                  <div className="alert alert-warning">
                    <strong>€{delivery.shipmentCost}</strong>
                    <hr />
                    <small>{t('delivery.upsNotice')}</small>
                  </div>
                </Col>
              </FormGroup>
            </>
          )}

          {delivery.comment && (
            <FormGroup row>
              <Col lg={4} xs={6}>
                <span>{t('common.comment')}</span>
              </Col>

              <StyledColBold xs={6} lg={8}>
                {delivery.comment}
              </StyledColBold>
            </FormGroup>
          )}
        </Col>

        {!isOpenEditMode && (
          <Col
            xs={12}
            className="d-flex h-100 justify-content-between gap-2 align-items-start"
            lg={5}
            xl={3}
          >
            {delivery.editable && (
              <StyledButtonGray
                className="w-50"
                onClick={() => handleEditDelivery(index)}
                disabled={!delivery.editable}
              >
                <i className="bx bxs-edit-alt" />
                {t('common.edit')}
              </StyledButtonGray>
            )}

            {!isOpenDeleteNotice && (
              <StyledButtonGray
                className="w-50 d-flex align-items-center justify-content-center"
                // onClick={() => handleOpenModal(delivery.id)}
                onClick={() =>
                  delivery.number
                    ? handleOpenDeleteNotice()
                    : handleOpenModal(delivery.id)
                }
                // disabled={!delivery.editable}
              >
                <i className="bx bxs-trash" />
                {t('common.delete')}
              </StyledButtonGray>
            )}
          </Col>
        )}
      </StyledField>

      {isOpenEditMode && (
        <DeliveryEdit
          deliveries={deliveries}
          setDeliveries={setDeliveries}
          handleCloseEditMode={handleCloseEditMode}
          delivery={delivery}
          index={index}
        />
      )}
    </>
  );
};
