import { Layout } from 'components/Layout';
import { PaginationLm } from 'components/Common/PaginationLm';
import { UncontrolledTooltip } from 'reactstrap';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Row, Col } from 'reactstrap';

import { useTranslation } from 'react-i18next';

import {
  PAGES,
  FIRST_PAGE,
  EMPTY_VALUE_WITH_LABEL,
  DATE_FORMAT_WITH_SYMBOL,
  DELIVERY,
} from '../../common/data/constants';
import { formatDate } from 'helpers/formatDate';
import Loader from 'components/Common/Loader';
import { PerPageSelect } from 'components/Common/PerPageSelect';

import {
  operatorGetDeliveryShipments,
  operatorDeliveryShipmentDelete,
} from 'store/actions';
import { debounce } from 'lodash';
import { convertDateToAPI } from 'helpers/converters';
import { StyledTable } from 'components/Table/StyledTable';
import { ShadowCard } from 'components/Common/ShadowCard';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { Badge, BlueLink } from 'components/Common';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { getShipmentLabelPdf } from 'helpers/downloadDocument';
import { useModal } from 'hooks/useModal';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';

export const DELIVERY_STATUSES = [
  { label: 'delivery.notIssued', value: 'not_issued' },
  { label: 'delivery.onTheWay', value: 'on_the_way' },
  { label: 'delivery.delivered', value: 'delivered' },
];

const Deliveries = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, loading } = useSelector(
    state => state.admin.delivery.shipments,
  );
  const [perPage, setPerPage] = useState(PAGES[0]);
  const [page, setPage] = useState(FIRST_PAGE);

  const [searchParams, setSearchParams] = useState({
    orderNumber: '',
    provider: EMPTY_VALUE_WITH_LABEL,
    startDate: undefined,
    endDate: undefined,
    status: EMPTY_VALUE_WITH_LABEL,
  });

  const showPagination = perPage.value < data?.count;
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();

  const handleUpdateSearch = (field, value) => {
    setSearchParams(prev => ({
      ...prev,
      [field]: value,
    }));

    setPage(FIRST_PAGE);
  };

  const handleChangeDateRange = dates => {
    const [start, end] = dates;
    setSearchParams({ ...searchParams, startDate: start, endDate: end });
    setPage(FIRST_PAGE);
  };

  useEffect(() => {
    dispatch(
      operatorGetDeliveryShipments({
        order: searchParams.orderNumber,
        carrier: searchParams.provider?.value,
        status: searchParams.status.value,
        created_at_start: convertDateToAPI(searchParams.startDate),
        created_at_end: convertDateToAPI(searchParams.endDate),
        page,
        page_size: perPage.value,
      }),
    );
  }, [searchParams, page, perPage]);

  const handleDeleteDelivery = id => {
    dispatch(operatorDeliveryShipmentDelete({ id, handleCloseModal }));
  };

  return (
    <Layout title={t('sideBar.delivery')}>
      <ShadowCard>
        <Row className="align-items-center">
          <Row className="mb-3 pe-0">
            <Col xxl={3} md={3} xs={2} className="pe-3">
              <SearchInput
                handleChange={debounce(
                  event =>
                    handleUpdateSearch('orderNumber', event.target.value),
                  200,
                )}
              />
            </Col>

            <Col xxl={3} xs={5} lg={4} className="ps-0 d-flex pe-3">
              <DateRangePicker
                placeholder="delivery.creationDate"
                onChange={handleChangeDateRange}
                endDate={searchParams.endDate}
                startDate={searchParams.startDate}
              />
            </Col>

            <Col xs={3} lg={3} xxl={4} className="ps-0 d-flex">
              <StatusPicker
                $type="gray"
                className="me-3"
                value={searchParams.provider}
                handlePickStatus={value =>
                  handleUpdateSearch('provider', value)
                }
                placeholder="delivery.provider"
                statuses={DELIVERY}
              />

              <StatusPicker
                $type="gray"
                value={searchParams.status}
                handlePickStatus={value => handleUpdateSearch('status', value)}
                placeholder="common.status"
                statuses={DELIVERY_STATUSES}
              />
            </Col>

            <Col
              xs={2}
              className="d-flex align-items-center justify-content-end p-0"
            >
              <PerPageSelect
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
              />
            </Col>
          </Row>

          {isOpen && (
            <ModalConfirmDelete
              isOpenModal={isOpen}
              textHeader="delivery.delete"
              textBody="delivery.deleteConfirm"
              handleCloseModal={handleCloseModal}
              handleDelete={() => handleDeleteDelivery(isOpen.id)}
            />
          )}

          {loading && (
            <Col xs={12} className="d-flex justify-content-center">
              <Loader isLoading={loading} />
            </Col>
          )}

          {!loading && data && data.results.length === 0 && (
            <Col xs={12} className="d-flex justify-content-center">
              <Alert color="info" className="w-100">
                {t('delivery.notFound')}
              </Alert>
            </Col>
          )}

          {!loading && data && data.results.length > 0 && (
            <>
              <div className="table-responsive">
                <StyledTable>
                  <thead>
                    <tr>
                      <th>{t('delivery.deliveryId')}</th>
                      <th>{t('delivery.creationDate')}</th>
                      <th>{t('delivery.deliveryServiceProvider')}</th>
                      <th>{t('delivery.order')}</th>
                      <th>{t('delivery.from')}</th>
                      <th>{t('delivery.to')}</th>
                      <th>{t('common.comment')}</th>
                      <th>{t('common.status')}</th>
                      <th />
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {data.results.map(delivery => (
                      <tr key={delivery.id}>
                        <td>
                          <BlueLink to={`/deliveries/${delivery.id}`}>
                            {delivery.id}
                          </BlueLink>
                        </td>

                        <td>
                          {formatDate(
                            delivery.created_at,
                            DATE_FORMAT_WITH_SYMBOL,
                          )}
                        </td>

                        <td>
                          {delivery.carrier?.carrier_display ?? '-'}
                          {delivery?.track_number && (
                            <>
                              <br />
                              <em className="text-info text-nowrap">
                                TN: {delivery?.track_number}
                              </em>
                            </>
                          )}
                        </td>

                        <td>
                          {delivery?.order ? (
                            <BlueLink
                              key={delivery.order.id}
                              to={`/orders/${delivery.order.id}`}
                              className="text-nowrap"
                            >
                              {delivery.order.number}
                            </BlueLink>
                          ) : (
                            '-'
                          )}
                          {delivery?.order?.order_client && (
                            <>
                              <br />
                              <span className="text-muted">
                                {delivery?.order?.order_client}
                              </span>
                            </>
                          )}
                        </td>
                        <td className="text-muted">
                          <small>
                            {delivery.from_company && (
                              <>
                                {delivery.from_company},<br />
                              </>
                            )}
                            {delivery.from_name && (
                              <>
                                {delivery.from_name},<br />
                              </>
                            )}
                            {delivery.from_city && <>{delivery.from_city}, </>}
                            {delivery.from_country_name && (
                              <>{delivery.from_country_name}</>
                            )}
                          </small>
                        </td>
                        <td className="text-muted">
                          <small>
                            {delivery.to_company && (
                              <>
                                {delivery.to_company},<br />
                              </>
                            )}
                            {delivery.to_name && (
                              <>
                                {delivery.to_name},<br />
                              </>
                            )}
                            {delivery.to_city && <>{delivery.to_city}, </>}
                            {delivery.to_country_name && (
                              <>{delivery.to_country_name}</>
                            )}
                          </small>
                        </td>
                        <td>
                          {delivery?.comment && (
                            <span className="text-muted">
                              <small>
                                <em>
                                  {delivery?.comment?.length > 120 ? (
                                    <>
                                      {delivery?.comment.substring(0, 100)}...
                                    </>
                                  ) : (
                                    <>{delivery?.comment}</>
                                  )}
                                </em>
                              </small>
                            </span>
                          )}
                        </td>

                        <td>
                          <Badge
                            color={convertStatusToColor(delivery.status)}
                            text={delivery.status}
                          />
                        </td>

                        <td>
                          {delivery?.shipment_label_link && (
                            <>
                              <div>
                                <StyledEmptyButton
                                  id={`shipment-label-pdf-${delivery.id}`}
                                  onClick={() => getShipmentLabelPdf(delivery)}
                                >
                                  <i className="bx bxs-file-pdf" />
                                </StyledEmptyButton>
                                <UncontrolledTooltip
                                  placement="left"
                                  autohide
                                  target={`shipment-label-pdf-${delivery.id}`}
                                >
                                  {t('delivery.getShipmentLabelPdf')}
                                </UncontrolledTooltip>
                              </div>
                            </>
                          )}
                        </td>

                        <td>
                          {delivery?.is_deletable && (
                            <>
                              <StyledEmptyButton
                                id={`shipment-remove-item-${delivery.id}`}
                                onClick={() => handleOpenModal(delivery)}
                              >
                                <i
                                  id={`trash-i-${delivery.id}`}
                                  className="bx bxs-trash-alt"
                                />
                              </StyledEmptyButton>
                              <UncontrolledTooltip
                                placement="left"
                                autohide
                                target={`shipment-remove-item-${delivery.id}`}
                              >
                                {t('common.delete')}
                              </UncontrolledTooltip>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </div>

              <Col xs={12}>
                {showPagination && (
                  <PaginationLm
                    count={data.count}
                    perPage={perPage.value}
                    active={page}
                    selectPage={page => setPage(page)}
                  />
                )}
              </Col>
            </>
          )}
        </Row>
      </ShadowCard>
    </Layout>
  );
};

export default Deliveries;
