import { call, put, select, takeEvery } from 'redux-saga/effects';
import { showToastError } from 'helpers/utils/toast';
import {
  collectAddresses,
  createAddresses,
  deleteAddress,
  getAddresses,
  updateAddress,
} from './actions';
import i18n from 'i18n';
import {
  fetchAddresses,
  deleteAddressRequest,
  postAddress,
  putAddress,
} from 'helpers/api-requests/references';
import { getAddressString } from 'helpers/getAddressString';

function* getAddressesSaga({ payload }) {
  try {
    const response = yield call(fetchAddresses, payload);
    if (response.success) {
      yield put(getAddresses.success(response.data.results));
    } else {
      showToastError(response.message);
      yield put(getAddresses.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(getAddresses.failure());
  }
}

function* deleteAddressesSaga({ payload }) {
  try {
    const response = yield call(deleteAddressRequest, payload);

    if (response.success) {
      yield put(deleteAddress.success(payload));
    } else {
      showToastError(response.message);
      yield put(deleteAddress.failure());
    }
  } catch (error) {
    showToastError(i18n.t('client.errorDeleteAddress'));
    yield put(deleteAddress.failure());
  }
}

function* updateAddressSaga({ payload }) {
  try {
    const response = yield call(putAddress, payload.id, payload.address);
    if (response.success) {
      yield put(
        updateAddress.success({ id: payload.id, address: response.data }),
      );
      payload.closeEdit();
    } else {
      showToastError(response.message);
      yield put(updateAddress.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(updateAddress.failure());
  }
}

function* createAddressSaga({ payload }) {
  try {
    const response = yield call(postAddress, payload.address);
    if (response.success) {
      yield put(createAddresses.success(response.data));
      payload.closeEdit();
    } else {
      showToastError(response.message);
      yield put(createAddresses.failure());
    }
  } catch (error) {
    showToastError(error);
    yield put(createAddresses.failure());
  }
}

function* collectAvailableAddresses() {
  const state = yield select();

  // collect Visa Support offices addresses
  const configAddresses = state.references.config.addresses.map(address => ({
    value: 'config-address',
    label: `Visa Support ${address.name} - ${address.country?.name || ''}, ${
      address.state || ''
    } ${address.city || ''} ${address.address1 || ''}`,
    address: {
      ...address,
      country: state.references.countries.find(
        country => country.country.code === address.country.code,
      ),
    },
  }));

  // Collect order's consulate addresses
  const consulate = state.order.order.order.consulate;
  const consulateOption =
    consulate && !!consulate.address
      ? {
          value: 'consulate-address',
          label: `Consulate ${consulate.address.name} - ${getAddressString(
            consulate.address,
          )}`,
          address: consulate.address,
        }
      : null;
  // Collect order's client address (client have only 1 address)
  const client = state.order.order.order.client
    ? state.order.order.order.client
    : state.order.order.order?.customer_organization;

  const clientAddress =
    client.get_model_type === 'organization'
      ? client.address
      : client.addresses[0];

  let clientAddressOption = {};

  if (clientAddress && clientAddress.name) {
    clientAddressOption = {
      value: 'client-address',
      label: `Client ${
        client.name || client.full_name || ''
      } - ${getAddressString(clientAddress)}`,
      address: {
        ...clientAddress,
        email: client.email,
        phone: client.phone,
      },
    };
  }

  // Collect order's applicants addresses
  // Only addresses of applicants who selected delivery to address will be selected
  const orderApplicantsAddresses = state.order.order.order.customers
    .filter(
      applicant =>
        applicant.delivery_method.value === 'delivery_method_to_address',
    )
    .map(customer => {
      const customerAddress = {
        address1: customer.delivery_address1,
        city: customer.delivery_city,
        country: customer.delivery_country,
        name: customer.delivery_name,
        postalCode: customer.delivery_postal_code,
        state: customer.delivery_state,
        phone: customer.phone,
        email: customer.email,
      };
      return {
        value: `customer-adddress-${customer.id}`,
        label: `Applicant ${customer.delivery_name} - ${getAddressString(
          customerAddress,
        )}`,
        address: customerAddress,
      };
    });

  let availableAddresses = consulateOption
    ? [...configAddresses, consulateOption]
    : configAddresses;

  if (Object.keys(clientAddressOption).length > 0) {
    availableAddresses = availableAddresses.concat(clientAddressOption);
  }

  availableAddresses = availableAddresses.concat(...orderApplicantsAddresses, {
    value: 'other',
    label: 'Other',
  });

  yield put(collectAddresses.success(availableAddresses));
}

function* addressesSaga() {
  yield takeEvery(getAddresses, getAddressesSaga);
  yield takeEvery(deleteAddress, deleteAddressesSaga);
  yield takeEvery(updateAddress, updateAddressSaga);
  yield takeEvery(createAddresses, createAddressSaga);
  yield takeEvery(collectAddresses, collectAvailableAddresses);
}

export default addressesSaga;
