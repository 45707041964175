import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Row, Col, Alert, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  YES_NO_OPTIONS,
  PAGES,
  FIRST_PAGE,
  ORDER_STATUS,
  DATE_FORMAT_WITH_SYMBOL,
  EMPTY_VALUE_WITH_LABEL,
  ORDER_PAYMENT_STATUSES,
} from 'common/data/constants';
import { Layout } from 'components/Layout';
import { StyledTableOrders } from 'components/Table/StyledTableOrders';
import Loader from 'components/Common/Loader';
import { PerPageSelect } from 'components/Common/PerPageSelect';
import { SearchInput } from 'components/SearchInput/SerchInput';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { StatusPicker } from 'components/StatusPicker/StatusPicker';
import { PaginationLm } from 'components/Common/PaginationLm';
import {
  ButtonCreate,
  StyledButtonCreate,
} from 'components/Common/ButtonCreate';

import { getOrders } from 'store/actions';
import { formatCurrency } from 'helpers/formatCurrency';
import { formatDate } from 'helpers/formatDate';
import { downloadDocument } from 'helpers/downloadDocument';
import { convertDateToAPI } from 'helpers/converters';
import { ShadowCard } from 'components/Common/ShadowCard';
import { Badge } from 'components/Common';
import { convertStatusToColor } from 'helpers/styles/statusToColor';
import { BlueLink } from 'components/Common';
import {
  isUserEmployee,
  isUserClient,
  userCanViewPrice,
  isUserOperator,
  userCanCreateOrder,
} from 'helpers/jwt_helper';
import { useTable } from 'hooks/useTable';
import { ResponsiveFilters } from 'components/Common/ResponsiveFilters';
// import { OperatorSelector } from 'components/OperatorSelector/OperatorSelector';
import { AssignedToSelector } from 'components/Order/AssignedToSelector';
import { useCountries } from '../../hooks/useCountries';
import {
  downloadOrderLisrResultsXls,
  downloadOrderLisrResultsPdf,
} from 'helpers/api-requests/admin';
import { showToastError } from 'helpers/utils/toast';
import { OrdersCustomerRow } from './OrdersCustomerRow';
import { getOrderTypesRequest } from 'helpers/api-requests/admin';
import i18n from 'i18n';
import SortButton from 'components/SortButton';
import styled from 'styled-components';
import { debounce } from 'lodash';

const StyledText = styled.span`
  font-size: 14px;
  color: #6a7187;
  padding: 8px 8px;
  cursor: pointer;
  background-color: powderblue;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
`;

const showCreateBtn = userCanCreateOrder();
const STATUS_EMPTY_VALUE_WITH_LABEL = {
  value: EMPTY_VALUE_WITH_LABEL.value,
  label: 'All without drafts',
};

const additionalSearchFields = {
  status: isUserOperator
    ? STATUS_EMPTY_VALUE_WITH_LABEL
    : EMPTY_VALUE_WITH_LABEL,
  search: '',
  startDate: undefined,
  endDate: null,
  estimatedToConsulateStartDate: null,
  estimatedToConsulateEndDate: null,
  estimatedFromConsulateStartDate: null,
  estimatedFromConsulateEndDate: null,
  visaStartStartDate: null,
  visaStartEndDate: null,
  visaEndStartDate: null,
  visaEndEndDate: null,
  other_countries: EMPTY_VALUE_WITH_LABEL,
  perPage: PAGES[0],
  page: FIRST_PAGE,
  operator: EMPTY_VALUE_WITH_LABEL,
  country: EMPTY_VALUE_WITH_LABEL,
  consulate: null,
  payment_status: EMPTY_VALUE_WITH_LABEL,
  payment_caution: EMPTY_VALUE_WITH_LABEL,
  orderType: EMPTY_VALUE_WITH_LABEL,
};

const OERATOR_ORDER_FILTER_STATUSES = [
  { label: 'orders.status.with_drafts', value: 'with_drafts' },
  ...ORDER_STATUS,
];

const isAvailableOrder = true;

const Orders = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { t } = useTranslation();
  const hidePrice = !userCanViewPrice();
  const { countriesConsulatesOptionsShort, countriesOptionsShort } =
    useCountries(isAvailableOrder);
  const countriesOptions = isUserOperator
    ? countriesConsulatesOptionsShort
    : countriesOptionsShort;

  const [orderTypes, setOrderTypes] = useState([]);
  const [ordering, setOrdering] = useState('-pk');

  useEffect(() => {
    getOrderTypesRequest().then(response => {
      if (response.success) {
        setOrderTypes(
          response.data.results?.map(orderType => ({
            label: orderType?.translations?.[i18n.language],
            value: orderType.id,
          })),
        );
      }
    });
  }, [searchState]);

  const { orders, count, isLoading } = useSelector(
    state => state.admin.rest.orders,
  );
  const [isLoadFile, setIsLoadFile] = useState(false);
  const [isLoadPdfFile, setIsLoadPdfFile] = useState(false);

  const { showPagination, handleChangeSearchParam, searchState } = useTable(
    additionalSearchFields,
    count,
  );

  const PAYMENT_CAUTION_OPTIONS = [
    { label: t('order.yes'), value: true },
    { label: t('order.no'), value: false },
  ];

  const handleDownloadOrderLisrResultsXls = async () => {
    try {
      setIsLoadFile(true);

      const response = await downloadOrderLisrResultsXls(
        {
          search: searchState.search,

          timestamp_start: convertDateToAPI(searchState.startDate),
          timestamp_end: convertDateToAPI(searchState.endDate),

          status: searchState.status?.value ?? null,
          other_countries: searchState.other_countries?.value,

          estimated_to_consulate_start_date: convertDateToAPI(
            searchState.estimatedToConsulateStartDate,
          ),
          estimated_to_consulate_end_date: convertDateToAPI(
            searchState.estimatedToConsulateEndDate,
          ),

          estimated_from_consulate_start_date: convertDateToAPI(
            searchState.estimatedFromConsulateStartDate,
          ),
          estimated_from_consulate_end_date: convertDateToAPI(
            searchState.estimatedFromConsulateEndDate,
          ),

          visa_start_start_date: convertDateToAPI(
            searchState.visaStartStartDate,
          ),
          visa_start_end_date: convertDateToAPI(searchState.visaStartEndDate),

          visa_end_start_date: convertDateToAPI(searchState.visaEndStartDate),
          visa_end_end_date: convertDateToAPI(searchState.visaEndEndDate),
          country: getCountryConsulateIds(searchState.country.value).countryId,
          consulate: getCountryConsulateIds(searchState.country.value)
            .consulateId,
          assigned_to: searchState.operator?.value,
          payment_status: searchState.payment_status?.value,
          payment_caution: searchState.payment_caution?.value,
          order_type: searchState.orderType.value,
        },
        { responseType: 'blob' },
      );
      setIsLoadFile(false);
      downloadDocument(response, 'Results.xlsx');
    } catch (err) {
      setIsLoadFile(false);
      showToastError(err.message);
    }
  };

  const handleDownloadOrderLisrResultsPdf = async () => {
    try {
      setIsLoadPdfFile(true);

      const response = await downloadOrderLisrResultsPdf(
        {
          search: searchState.search,

          timestamp_start: convertDateToAPI(searchState.startDate),
          timestamp_end: convertDateToAPI(searchState.endDate),

          status: searchState.status?.value ?? null,
          other_countries: searchState.other_countries?.value,

          estimated_to_consulate_start_date: convertDateToAPI(
            searchState.estimatedToConsulateStartDate,
          ),
          estimated_to_consulate_end_date: convertDateToAPI(
            searchState.estimatedToConsulateEndDate,
          ),

          estimated_from_consulate_start_date: convertDateToAPI(
            searchState.estimatedFromConsulateStartDate,
          ),
          estimated_from_consulate_end_date: convertDateToAPI(
            searchState.estimatedFromConsulateEndDate,
          ),

          visa_start_start_date: convertDateToAPI(
            searchState.visaStartStartDate,
          ),
          visa_start_end_date: convertDateToAPI(searchState.visaStartEndDate),

          visa_end_start_date: convertDateToAPI(searchState.visaEndStartDate),
          visa_end_end_date: convertDateToAPI(searchState.visaEndEndDate),
          country: getCountryConsulateIds(searchState.country.value).countryId,
          consulate: getCountryConsulateIds(searchState.country.value)
            .consulateId,
          assigned_to: searchState.operator?.value,
          payment_status: searchState.payment_status?.value,
          payment_caution: searchState.payment_caution?.value,
          page: searchState.page,
          page_size: searchState.perPage.value ?? 25,
          order_type: searchState.orderType.value,
        },
        { responseType: 'blob' },
      );
      setIsLoadPdfFile(false);
      downloadDocument(response, 'Results.pdf');
    } catch (err) {
      setIsLoadPdfFile(false);
      showToastError(err.message);
    }
  };

  const getCountryConsulateIds = countryValue => {
    let countryId = null;
    let consulateId = null;

    if (Number.isInteger(countryValue)) {
      countryId = countryValue;
    } else {
      const countryParts = countryValue.toString().split('_');
      countryId = countryParts[0];
      consulateId = countryParts.length > 1 ? countryParts[1] : '';
    }
    return {
      countryId: countryId,
      consulateId: consulateId,
    };
  };

  const filterData = () => {
    const {
      perPage,
      page,
      status,
      other_countries,
      startDate,
      endDate,

      estimatedToConsulateStartDate,
      estimatedToConsulateEndDate,

      estimatedFromConsulateStartDate,
      estimatedFromConsulateEndDate,

      visaStartStartDate,
      visaStartEndDate,

      visaEndStartDate,
      visaEndEndDate,

      payment_status,
      payment_caution,
      search,
      country,
      operator,
      orderType,
    } = searchState;
    const timeStamps = {
      timestamp_start: convertDateToAPI(startDate),
      timestamp_end: convertDateToAPI(endDate),
      estimated_to_consulate_start_date: convertDateToAPI(
        estimatedToConsulateStartDate,
      ),
      estimated_to_consulate_end_date: convertDateToAPI(
        estimatedToConsulateEndDate,
      ),
      estimated_from_consulate_start_date: convertDateToAPI(
        estimatedFromConsulateStartDate,
      ),
      estimated_from_consulate_end_date: convertDateToAPI(
        estimatedFromConsulateEndDate,
      ),
      visa_start_start_date: convertDateToAPI(visaStartStartDate),
      visa_start_end_date: convertDateToAPI(visaStartEndDate),
      visa_end_start_date: convertDateToAPI(visaEndStartDate),
      visa_end_end_date: convertDateToAPI(visaEndEndDate),
    };

    const countryId = getCountryConsulateIds(country.value).countryId;
    const consulateId = getCountryConsulateIds(country.value).consulateId;

    dispatch(
      getOrders({
        status: status ? status.value : null,
        other_countries: other_countries?.value,
        payment_status: payment_status?.value,
        payment_caution: payment_caution?.value,
        order_type: orderType?.value,
        search,
        page,
        page_size: perPage.value,
        assigned_to: operator.value,
        country: countryId,
        consulate: consulateId,
        ordering: ordering,
        ...timeStamps,
      }),
    );
  };

  useEffect(() => {
    filterData();
  }, [searchState, ordering]);

  // If search filters are used.
  const [searchHasValue, setSearchHasValue] = useState(false);

  useEffect(() => {
    setSearchHasValue(
      searchState?.length > 0 ||
        [
          searchState?.country,
          searchState?.operator,
          searchState?.orderType,
          searchState?.other_countries,
          searchState?.payment_caution,
          searchState?.payment_status,
          searchState?.status,
        ].some(el => el?.value) ||
        [
          searchState?.consulate,
          searchState?.endDate,
          searchState?.estimatedFromConsulateEndDate,
          searchState?.estimatedFromConsulateStartDate,
          searchState?.estimatedToConsulateEndDate,
          searchState?.estimatedToConsulateStartDate,
          // searchState?.search, todo
          searchState?.startDate,
          searchState?.visaEndEndDate,
          searchState?.visaEndStartDate,
          searchState?.visaStartEndDate,
          searchState?.visaStartStartDate,
        ].some(val => val) ||
        searchState?.page != additionalSearchFields.page ||
        searchState?.perPage != additionalSearchFields.perPage,
    );
  }, [searchState]);

  const resetFilters = () => {
    handleChangeSearchParam('country', additionalSearchFields.country);
    handleChangeSearchParam('operator', additionalSearchFields.operator);
    handleChangeSearchParam('orderType', additionalSearchFields.orderType);
    handleChangeSearchParam(
      'other_countries',
      additionalSearchFields.other_countries,
    );
    handleChangeSearchParam(
      'payment_caution',
      additionalSearchFields.payment_caution,
    );
    handleChangeSearchParam('status', additionalSearchFields.status);
    handleChangeSearchParam('consulate', additionalSearchFields.consulate);
    handleChangeSearchParam('endDate', additionalSearchFields.endDate);
    handleChangeSearchParam(
      'estimatedFromConsulateEndDate',
      additionalSearchFields.estimatedFromConsulateEndDate,
    );
    handleChangeSearchParam(
      'estimatedFromConsulateStartDate',
      additionalSearchFields.estimatedFromConsulateStartDate,
    );
    handleChangeSearchParam(
      'estimatedToConsulateEndDate',
      additionalSearchFields.estimatedToConsulateEndDate,
    );
    handleChangeSearchParam(
      'estimatedToConsulateStartDate',
      additionalSearchFields.estimatedToConsulateStartDate,
    );
    // handleChangeSearchParam('search', additionalSearchFields.search);  // todo
    handleChangeSearchParam('startDate', additionalSearchFields.startDate);
    handleChangeSearchParam(
      'visaEndEndDate',
      additionalSearchFields.visaEndEndDate,
    );
    handleChangeSearchParam(
      'visaEndStartDate',
      additionalSearchFields.visaEndStartDate,
    );
    handleChangeSearchParam(
      'visaStartEndDate',
      additionalSearchFields.visaStartEndDate,
    );
    handleChangeSearchParam('page', additionalSearchFields.page);
    handleChangeSearchParam('perPage', additionalSearchFields.perPage);
  };

  return (
    <Layout
      title="sideBar.order"
      extraContent={
        <div className="w-100 d-flex align-items-center justify-content-between">
          {showCreateBtn && (
            <ButtonCreate handleCreate={() => push('/orders/create')} />
          )}
          {isUserOperator && orders.length > 0 && (
            <>
              <StyledButtonCreate
                color="success"
                onClick={handleDownloadOrderLisrResultsPdf}
                className="btn-sm"
                style={{ float: 'right!important' }}
                disabled={isLoadPdfFile}
              >
                {isLoadPdfFile ? (
                  <>
                    <Spinner
                      className="me-2"
                      style={{ padding: '0.75rem' }}
                      color="light"
                      size="sm"
                      type="grow"
                    >
                      Loading...
                    </Spinner>
                    Exporting data available on page: {searchState.page}
                  </>
                ) : (
                  <>
                    <i className="bx bx-download me-2" />
                    {t('reports.exportToPdf')}
                  </>
                )}
              </StyledButtonCreate>

              <StyledButtonCreate
                color="success"
                onClick={handleDownloadOrderLisrResultsXls}
                className="btn-sm"
                style={{ float: 'right' }}
              >
                {isLoadFile ? (
                  <Spinner
                    className="me-2"
                    style={{ padding: '0.75rem' }}
                    color="light"
                    size="sm"
                    type="grow"
                  >
                    Loading...
                  </Spinner>
                ) : (
                  <i className="bx bx-download me-2" />
                )}
                {t('reports.exportToXls')}
              </StyledButtonCreate>
            </>
          )}
        </div>
      }
    >
      <ShadowCard>
        <ResponsiveFilters
          lastItem={
            <PerPageSelect
              perPage={searchState.perPage}
              setPerPage={perPage =>
                handleChangeSearchParam('perPage', perPage)
              }
              setPage={page => handleChangeSearchParam('page', page)}
            />
          }
          count={{ label: 'Total orders found', value: count }}
        >
          <div className="me-3 mb-3">
            <SearchInput
              defaultValue={searchState.search}
              handleChange={debounce(
                event => handleChangeSearchParam('search', event.target.value),
                500,
              )}
            />
          </div>

          <div className="me-3 mb-3" style={{ minWidth: 300 }}>
            <DateRangePicker
              onChange={range => handleChangeSearchParam('dateRange', range)}
              endDate={searchState.endDate}
              startDate={searchState.startDate}
            />
          </div>

          <div className="me-3 mb-3">
            <StatusPicker
              placeholder="order.orderType"
              $type="gray"
              statuses={orderTypes}
              handlePickStatus={value =>
                handleChangeSearchParam('orderType', value)
              }
              value={searchState.orderType}
            />
          </div>

          <div className="me-3 mb-3">
            <StatusPicker
              $type="gray"
              value={searchState.status}
              handlePickStatus={status =>
                handleChangeSearchParam('status', status)
              }
              statuses={
                isUserOperator ? OERATOR_ORDER_FILTER_STATUSES : ORDER_STATUS
              }
              empty_value_with_label={
                isUserOperator
                  ? STATUS_EMPTY_VALUE_WITH_LABEL
                  : EMPTY_VALUE_WITH_LABEL
              }
            />
          </div>

          <div className="me-3 mb-3">
            <StatusPicker
              placeholder="common.country"
              $type="gray"
              statuses={countriesOptions}
              handlePickStatus={value =>
                handleChangeSearchParam('country', value)
              }
              value={searchState.country}
            />
          </div>

          {isUserOperator && (
            <div className="me-3 mb-3">
              <AssignedToSelector
                placeholder="order.assignedTo"
                handlePickOperator={operator =>
                  handleChangeSearchParam('operator', operator)
                }
                value={searchState.operator}
              />
            </div>
          )}

          {isUserOperator && (
            <div className="me-3 mb-3">
              <StatusPicker
                placeholder={t('order.requiredVisa')}
                $type="gray"
                value={searchState.other_countries}
                handlePickStatus={option =>
                  handleChangeSearchParam('other_countries', option)
                }
                statuses={[...YES_NO_OPTIONS]}
                empty_value_with_label={EMPTY_VALUE_WITH_LABEL}
              />
            </div>
          )}

          {isUserOperator && (
            <>
              <div className="me-3 mb-3" style={{ minWidth: 300 }}>
                <DateRangePicker
                  placeholder={t('orders.estimatedToConsulateDateRange')}
                  onChange={range =>
                    handleChangeSearchParam(
                      'estimatedToConsulateDateRange',
                      range,
                    )
                  }
                  endDate={searchState.estimatedToConsulateEndDate}
                  startDate={searchState.estimatedToConsulateStartDate}
                />
              </div>

              <div className="me-3 mb-3" style={{ minWidth: 300 }}>
                <DateRangePicker
                  placeholder={t('orders.estimatedFromConsulateDateRange')}
                  onChange={range =>
                    handleChangeSearchParam(
                      'estimatedFromConsulateDateRange',
                      range,
                    )
                  }
                  endDate={searchState.estimatedFromConsulateEndDate}
                  startDate={searchState.estimatedFromConsulateStartDate}
                />
              </div>
            </>
          )}

          {isUserOperator && (
            <>
              <div className="me-3 mb-3" style={{ minWidth: 300 }}>
                <DateRangePicker
                  placeholder={t('orders.visaStartRange')}
                  onChange={range =>
                    handleChangeSearchParam('visaStartDateRange', range)
                  }
                  endDate={searchState.visaStartEndDate}
                  startDate={searchState.visaStartStartDate}
                />
              </div>

              <div className="me-3 mb-3" style={{ minWidth: 300 }}>
                <DateRangePicker
                  placeholder={t('orders.visaEndRange')}
                  onChange={range =>
                    handleChangeSearchParam('visaEndDateRange', range)
                  }
                  endDate={searchState.visaEndEndDate}
                  startDate={searchState.visaEndStartDate}
                />
              </div>
            </>
          )}

          {isUserOperator && (
            <>
              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder={t('orders.paymentStatus')}
                  $type="gray"
                  value={searchState.payment_status}
                  handlePickStatus={option =>
                    handleChangeSearchParam('payment_status', option)
                  }
                  statuses={[...ORDER_PAYMENT_STATUSES]}
                  empty_value_with_label={EMPTY_VALUE_WITH_LABEL}
                />
              </div>
            </>
          )}

          {isUserOperator && (
            <>
              <div className="me-3 mb-3">
                <StatusPicker
                  placeholder={t('orders.paymentCaution')}
                  $type="gray"
                  value={searchState.payment_caution}
                  handlePickStatus={option =>
                    handleChangeSearchParam('payment_caution', option)
                  }
                  statuses={[...PAYMENT_CAUTION_OPTIONS]}
                  empty_value_with_label={EMPTY_VALUE_WITH_LABEL}
                />
              </div>
            </>
          )}

          {isUserOperator && searchHasValue && (
            <div className="me-3 mb-3">
              <StyledText onClick={resetFilters} className="me-2">
                {t('common.resetFilters')}
              </StyledText>
            </div>
          )}
        </ResponsiveFilters>

        {isLoading && (
          <Row className="d-flex justify-content-center">
            <Loader isLoading />
          </Row>
        )}

        {!isLoading && orders.length === 0 && (
          <Alert color="info">{t('orders.notFound')}</Alert>
        )}

        {!isLoading && orders.length > 0 && (
          <>
            <StyledTableOrders responsive>
              <thead>
                <tr>
                  <th>
                    {t('common.number')}
                    <SortButton
                      ordering={ordering}
                      name={'pk'}
                      onSort={setOrdering}
                    />
                  </th>

                  <th>
                    {t('invoices.created')}
                    <SortButton
                      ordering={ordering}
                      name={'created_at'}
                      onSort={setOrdering}
                    />
                  </th>

                  {!(isUserClient || isUserEmployee) && (
                    <th>
                      {t('orders.customer')}
                      <SortButton
                        ordering={ordering}
                        name={'_client'}
                        onSort={setOrdering}
                      />
                    </th>
                  )}

                  <th className="text-center">
                    {isUserOperator
                      ? t('common.countryConsulate')
                      : t('common.country')}
                    <SortButton
                      ordering={ordering}
                      name={'country'}
                      onSort={setOrdering}
                    />
                  </th>

                  {!isUserEmployee && (
                    <th className="text-center">
                      {t('common.status')}
                      <SortButton
                        ordering={ordering}
                        name={'status'}
                        onSort={setOrdering}
                      />
                    </th>
                  )}

                  {!hidePrice && (
                    <th className="text-center">
                      {t('orders.orderCost')}
                      <SortButton
                        ordering={ordering}
                        name={'total_price'}
                        onSort={setOrdering}
                      />
                    </th>
                  )}

                  <th>{t('common.applicant')}</th>
                  {isUserOperator && (
                    <th>{t('order.otherCountries.header')}</th>
                  )}

                  {isUserOperator && (
                    <th>{t('orders.estimatedToConsulateDateRange')}</th>
                  )}
                  {isUserOperator && (
                    <th>{t('orders.estimatedFromConsulateDateRange')}</th>
                  )}
                  {isUserOperator && <th>{t('order.gultig')} from</th>}
                  {isUserOperator && <th>{t('order.gultig')} to</th>}
                  {isUserOperator && (
                    <th>
                      {t('order.assignedTo')}
                      <SortButton
                        ordering={ordering}
                        name={'assigned_to'}
                        onSort={setOrdering}
                      />
                    </th>
                  )}
                  {isUserOperator && (
                    <th>
                      {t('orders.lastUpdate')}
                      <SortButton
                        ordering={ordering}
                        name={'last_update_by'}
                        onSort={setOrdering}
                      />
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {orders.map(
                  (
                    {
                      id,
                      number,
                      created_at,
                      total_price,
                      country,
                      customers,
                      customer,
                      client,
                      assigned_to,
                      last_update_by,
                      status,
                      // order_is_paid,
                      order_type,
                      consulate,
                      payment_status,
                      payment_caution,
                    },
                    idx,
                  ) => (
                    <>
                      <tr key={id} className={idx % 2 == 1 && 'even'}>
                        <td
                          className="text-nowrap"
                          rowSpan={customers?.length || 1}
                        >
                          <div>
                            <Badge className="mb-2" text={order_type} />
                          </div>

                          <BlueLink to={`/orders/${id}`}>{number}</BlueLink>
                        </td>

                        <td
                          className="text-nowrap"
                          rowSpan={customers?.length || 1}
                        >
                          {formatDate(created_at, DATE_FORMAT_WITH_SYMBOL)}
                        </td>

                        {!(isUserClient || isUserEmployee) && (
                          <td rowSpan={customers?.length || 1}>{client}</td>
                        )}

                        <td
                          className="text-center"
                          rowSpan={customers?.length || 1}
                        >
                          {country}
                          {isUserOperator && (
                            <>
                              <br />
                              <em className="text-muted">{consulate}</em>
                            </>
                          )}
                        </td>

                        {!isUserEmployee && (
                          <td
                            className="text-center"
                            rowSpan={customers?.length || 1}
                          >
                            <Badge
                              color={convertStatusToColor(status)}
                              text={status}
                            />
                          </td>
                        )}

                        {!hidePrice && (
                          <td
                            className="text-center"
                            rowSpan={customers?.length || 1}
                          >
                            {formatCurrency(total_price)}
                            {isUserOperator && (
                              <Badge
                                // color={
                                //   order_is_paid
                                //     ? typeColor.success
                                //     : typeColor.danger
                                // }
                                // text={order_is_paid ? 'paid' : 'not paid'}
                                color={convertStatusToColor(payment_status)}
                                text={`${
                                  ORDER_PAYMENT_STATUSES.find(
                                    status => status.value === payment_status,
                                  )?.label
                                } ${payment_caution ? '!' : ''}`}
                              />
                            )}
                          </td>
                        )}

                        <OrdersCustomerRow
                          customer={customers ? customers[0] : customer}
                        />

                        {isUserOperator && (
                          <td rowSpan={customers?.length || 1}>
                            {assigned_to?.full_name}
                          </td>
                        )}
                        {isUserOperator && (
                          <td rowSpan={customers?.length || 1}>
                            {last_update_by?.full_name}
                          </td>
                        )}
                      </tr>
                      {customers?.length > 1 &&
                        customers.slice(1).map(customer => (
                          <tr
                            key={`${id}-${customer.id}`}
                            className={idx % 2 == 1 && 'even'}
                          >
                            <OrdersCustomerRow customer={customer} />
                          </tr>
                        ))}
                    </>
                  ),
                )}
              </tbody>
            </StyledTableOrders>

            <Row>
              <Col xs={12}>
                {showPagination && (
                  <PaginationLm
                    count={count}
                    perPage={searchState.perPage.value}
                    active={searchState.page}
                    selectPage={page => handleChangeSearchParam('page', page)}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </ShadowCard>
    </Layout>
  );
};

export default Orders;
