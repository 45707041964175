import { StyledTable } from 'components/Table/StyledTable';
import {
  getApplicantDocumentFilter,
  uploadOrderApplicantDocuments,
} from 'helpers/api-requests/admin';
import { useFormikContext } from 'formik';
import { showToastError } from 'helpers/utils/toast';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { StyledEmptyButton } from 'pages/Tariff/TariffServiceRow';
import { useModal } from 'hooks/useModal';
import { DocumentDetailModal } from './DocumentDetailModal';
import { useLoader } from 'hooks/useLoader';
import { useDropzone } from 'react-dropzone';
import { optionalField } from 'forms/shortcuts';
import { authLink } from 'helpers/jwt-token-access/auth-token-header';
import { ModalConfirmDelete } from 'components/Common/ModalConfirmDelete';
import { deleteApplicantDocumentRequest } from 'helpers/api-requests/admin';
import { isUserOperator, maxUploadSize } from 'helpers/jwt_helper';
import { EditFilename } from 'components/Common/editFilename';

const ERROR_TEXT_MAX_SIZE_FILE = `The file must not exceed ${
  maxUploadSize() / (1024 * 1024)
} MB`;

export const StyledUpload = styled(Button)`
  background-color: ${({ theme }) => theme.colors.gray200};
  border: none;
  color: ${({ theme }) => theme.colors.mainText}!important;
  padding: ${({ theme }) => theme.spacing.sm};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      color: ${theme.colors.gray600}!important;
      background-color: ${theme.colors.gray200}!important;
    `}
`;

const StyledFileName = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration-line: underline;
  font-weight: 500;
  font-size: 14px;
`;

const getColor = props => {
  if (props.isDragAccept) {
    return '#2196f3';
  }
  if (props.isDragReject) {
    return '#2196f3';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

// export const DROPZONE_CONFIG = {
//   multiple: false,
//   noKeyboard: true,
//   maxSize: 10485760,
//   accept: ACCEPT_FILE_TYPES,
//   onDrop: (_acceptedFiles, fileRejections) => {
// fileRejections.forEach(file => {
//   file.errors.forEach(error => {
//     if (error.code === 'file-too-large') {
//       showToastError(i18next.t(ERROR_TEXT_MAX_SIZE_FILE));
//     }
//   });
// });
// },
// };
function addAfter(array, index, newItem) {
  return [...array.slice(0, index), newItem, ...array.slice(index)];
}

export const ApplicantDocuments = ({ index }) => {
  const formik = useFormikContext();
  const { t } = useTranslation();
  const orderTypeId = formik.values.orderType;

  const [requiredDocuments, setRequiredDocuments] = useState({});
  const { handleCloseModal, handleOpenModal, isOpen } = useModal();
  const { Loader, handleEndLoad, handleStartLoad, notFound } = useLoader();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpenDocumentDelete, setIsOpenDocumentDelete] = useState(null);

  const handleCloseModalDelete = () => {
    setIsOpenDocumentDelete(null);
  };

  const handleDocumentDelete = id => {
    deleteApplicantDocumentRequest(id)
      .then(() => {
        // 1. Remove this doc for list on UI
        const updatedDocumetsList = requiredDocuments.documents.filter(
          document => document?.profile_document?.id !== id,
        );
        setRequiredDocuments({
          count: updatedDocumetsList.length,
          documents: updatedDocumetsList,
        });
        handleCloseModalDelete();
      })
      .catch(err => showToastError(err));
    // 2. Remove this doc from inmemory applicant documents list
    formik.setFieldValue(
      `applicants[${index}].files`,
      formik.values.applicants[index].files.filter(
        file => file.file.applicant_document_id !== id,
      ),
    );

    handleCloseModalDelete();
  };

  const isDisabledFields = useMemo(
    () => !formik.values.orderDetail?.isEditable,
    [formik.values.orderDetail?.isEditable],
  );

  useEffect(() => {
    const getRequiredDocuments = () => {
      if (isDisabledFields) {
        setRequiredDocuments({
          count: formik.values.applicants[index].files.length,
          documents: formik.values.applicants[index].files.map(
            file => file.file,
          ),
        });
        return;
      }

      handleStartLoad();

      getApplicantDocumentFilter({
        order_type: orderTypeId,
        number: formik.values.orderDetail?.number ?? null,
        client_id: formik.values.client.client,
        client_type: formik.values.client.clientType,
        nationality:
          formik.values.applicants[index].applicantDetail.citizenship,
        applicant: formik.values.applicants[index].applicantDetail.id,
        country: formik.values.client.country,
        previous_visa: formik.values.applicants[index].previusVisa,
        visa_type: formik.values.applicants[index].visaType,
        visa_duration: optionalField(
          formik.values.applicants[index].visaDuration,
        ),
        visa_urgency: optionalField(
          formik.values.applicants[index].visaUrgency,
        ),
        products: formik.values.applicants[index].services.map(
          service => service.product_id,
        ),
      })
        .then(response => {
          if (response.success) {
            setRequiredDocuments({
              count: response.data.documents.count,
              documents: response.data.documents.results,
            });

            if (isDisabledFields) {
              return;
            }

            const idOrderDocuments = formik.values.applicants[index].files.map(
              file => file.file.applicant_document_id,
            );

            let documentsForApplicant = [];

            response.data.documents.results?.forEach(document => {
              if (
                document.profile_document?.id &&
                !idOrderDocuments.includes(document.profile_document.id)
              ) {
                documentsForApplicant.push({
                  file: {
                    ...document.profile_document,
                    applicant_document_id: document.profile_document.id,
                  },
                });
              }
            });

            // const isRemove = isEqual(
            //   [
            //     ...formik.values.applicants[index].files,
            //     ...documentsForApplicant,
            //   ],
            //   formik.values.applicants[index].files,
            // );

            // if (isRemove) {
            //   formik.setFieldValue(`applicants[${index}].firstDateEntry`, null);

            //   formik.setFieldValue(
            //     `applicants[${index}].documentsToApplicantEstimatedAt`,
            //     null,
            //   );
            // }

            formik.setFieldValue(`applicants[${index}].files`, [
              ...formik.values.applicants[index].files,
              ...documentsForApplicant,
            ]);

            return;
          }

          showToastError(response.message);
        })
        .catch(err => console.error(err))
        .finally(handleEndLoad);
    };

    getRequiredDocuments();
  }, [formik.values.client, formik.values.applicants[index].applicantDetail]);

  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const ALLOWED_TYPES = {
    'application/pdf': ['.pdf'],
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'image/png': ['.png'],
    'image/tiff': ['.tiff'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    'application/msword': ['.doc'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'message/rfc822': ['.eml'],
    'application/vnd.ms-outlook': ['.msg'],
  };

  function StyledDropzone(props) {
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: ALLOWED_TYPES,
      maxSize: maxUploadSize(),
      onDrop: (_acceptedFiles, fileRejections) => {
        setSelectedFile(props.document);
        setAcceptedFiles(_acceptedFiles);
        fileRejections.forEach(file => {
          file.errors.forEach(error => {
            if (error.code === 'file-too-large') {
              showToastError(t(ERROR_TEXT_MAX_SIZE_FILE));
            }
            if (error.code === 'file-invalid-type') {
              // showToastError(error.message);
              var allowed_types_error = `${t(
                'order.errorAllowedDocumentsTypes',
              )} ${Object.keys(ALLOWED_TYPES)
                .map(val => ALLOWED_TYPES[val][0])
                .join(', ')}`;
              showToastError(allowed_types_error);
            }
          });
        });
      },
    });

    return (
      <div className="container">
        <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>{t('common.dragAndDropFile')}</p>
        </Container>
        {isUserOperator &&
          props.document?.options?.ALLOW_MULTIPLE &&
          !props.document?.options?.IS_ADMINISTRATIVE && (
            <small className="py-1 d-flex felx-align-center">
              {t('common.warning')}: {t('applicants.availableToOthersWarning')}
            </small>
          )}
      </div>
    );
  }

  const handleUploadFile = async (acceptedFiles, document) => {
    if (!document) {
      showToastError('Error while uploading document. Please try again');
      return;
    }
    const data = new FormData();
    data.append('document', document.id);
    data.append('file', acceptedFiles[0]);

    const nationality =
      formik.values.applicants[index].applicantDetail.citizenship;

    const country = formik.values.client.country;

    if (document.options.REQUIRE_COUNTRY) {
      data.append('country', country);
    }

    if (document.options.REQUIRE_NATIONALITY) {
      data.append('nationality', nationality);
    }

    const currentApplicantId =
      formik.values.applicants[index].applicantDetail.id;

    uploadOrderApplicantDocuments(currentApplicantId, data)
      .then(response => {
        if (response.success) {
          // formik.values.applicants?.[index].statusApplicant === 'draft' &&
          //   formik.setFieldValue(`applicants[${index}].firstDateEntry`, null);

          // formik.values.applicants?.[index].statusApplicant === 'draft' &&
          //   formik.setFieldValue(
          //     `applicants[${index}].documentsToApplicantEstimatedAt`,
          //     null,
          // );

          const isAllowMultipleDocument =
            response.data.document.options.ALLOW_MULTIPLE;

          if (isAllowMultipleDocument) {
            formik.setFieldValue(
              `applicants[${index}].files`,

              formik.values.applicants[index].files.concat({
                file: {
                  ...response.data,
                  applicant_document_id: response.data.id,
                },
              }),
            );
          }

          !isAllowMultipleDocument &&
            formik.setFieldValue(
              `applicants[${index}].files`,
              formik.values.applicants[index].files.find(
                file => file.file.applicant_document_id === response.data.id,
              )
                ? formik.values.applicants[index].files.map(file => {
                    if (file.file.applicant_document_id === response.data.id) {
                      return {
                        file: {
                          ...response.data,
                          applicant_document_id: response.data.id,
                        },
                      };
                    }

                    return file;
                  })
                : formik.values.applicants[index].files.concat({
                    file: {
                      ...response.data,
                      applicant_document_id: response.data.id,
                    },
                  }),
            );

          if (!isAllowMultipleDocument) {
            setRequiredDocuments(prev => ({
              ...prev,
              documents: prev.documents.map(file => {
                if (file.id === response.data.document.id) {
                  return { ...file, profile_document: response.data };
                }

                return file;
              }),
            }));
          } else {
            // we need to keep documents order as it is, original commented below for reference

            let tmp_docs = requiredDocuments.documents;
            let docIndex = tmp_docs.findIndex(
              obj => obj.key === response.data.document.key,
            );

            tmp_docs = addAfter(tmp_docs, docIndex, {
              description: response.data.document.description,
              example: response.data.document.example,
              id: response.data.document.id,
              key: response.data.document.key,
              name: response.data.document.name,
              options: response.data.document.options,
              profile_document: {
                applicant_pk: response.data.applicant_pk,
                country: null,
                nationality: null,
                id: response.data.id,
                original_name: response.data.original_name,
                url: response.data.url,
              },
            });

            // setRequiredDocuments(prev => ({
            //   ...prev,
            //   documents: prev.documents.concat({
            //     id: response.data.document.id,
            //     name: response.data.document.name,
            //     options: response.data.document.options,
            //     description: response.data.document.description,
            //     example: response.data.document.example,
            //     profile_document: {
            //       applicant_pk: response.data.applicant_pk,
            //       country: null,
            //       nationality: null,
            //       id: response.data.id,
            //       original_name: response.data.original_name,
            //       url: response.data.url,
            //     },
            //   }),
            // }));

            setRequiredDocuments({
              count: tmp_docs.length,
              documents: tmp_docs,
            });
          }
          return;
        }

        showToastError(response.message);
      })
      .catch(err => console.log(err))
      .finally(() => setSelectedFile(null));
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleUploadFile(acceptedFiles, selectedFile);
    }
  }, [acceptedFiles]);

  const [fileInEditMode, setFileInEditMode] = useState(null);
  const [isEditFilenameMode, setIsEditFilenameMode] = useState(false);

  const disableEditFilename = () => {
    setIsEditFilenameMode(false);
    setFileInEditMode(null);
  };

  const handleOpenEditMode = document => {
    setIsEditFilenameMode(true);
    setFileInEditMode(document);
  };

  const handleChangeFilename = event => {
    if (!fileInEditMode.profile_document) {
      setFileInEditMode(prev => ({
        ...prev,
        comment: event.target.value,
      }));
    } else {
      setFileInEditMode(prev => ({
        ...prev,
        profile_document: {
          ...prev.profile_document,
          comment: event.target.value,
        },
      }));
    }
  };

  const handleSaveFilename = () => {
    const files = formik.values.applicants?.[index].files;
    const fileIndex = files.findIndex(
      ({ file }) =>
        file.applicant_document_id ===
        (fileInEditMode?.profile_document?.id ??
          fileInEditMode.applicant_document_id),
    );
    const comment = fileInEditMode.profile_document
      ? fileInEditMode.profile_document.comment
      : fileInEditMode.comment;
    const prefix = `applicants[${index}].files[${fileIndex}]`;
    formik.setFieldValue(`${prefix}.file.comment`, comment);
    disableEditFilename();
  };

  const getCurrentFileDisplay = file => {
    const currentApplicantDocument = formik.values.applicants[index].files.find(
      // applicantFile => applicantFile.file?.document_id === file.id,
      applicantFile =>
        applicantFile.file?.document?.id === file.id ||
        applicantFile.file?.document_id === file.id,
    );

    if (!currentApplicantDocument && file.profile_document) {
      const fileObj = file;

      const fileName = fileObj.comment
        ? `${fileObj.comment}.${fileObj.original_name.split('.').pop()}`
        : fileObj.original_name;

      return (
        <>
          {fileObj.id !== fileInEditMode?.id && (
            <a
              href={authLink(file?.profile_document?.url)}
              className="text-primary"
              rel="noreferrer"
              target="_blank"
            >
              {isUserOperator ? fileName : fileObj.original_name}
            </a>
          )}

          {isUserOperator && fileObj.id !== fileInEditMode?.id && (
            <StyledEmptyButton
              className="ms-1"
              onClick={() => handleOpenEditMode(file)}
            >
              <i className="bx bx-edit-alt" />
            </StyledEmptyButton>
          )}

          {isEditFilenameMode && (
            <>
              <EditFilename
                defaultValue={fileName}
                handleCloseEdit={disableEditFilename}
                handleChangeFilename={handleChangeFilename}
                handleSaveFilename={handleSaveFilename}
                // disabled={isDisabledFields}
              />
            </>
          )}
        </>
      );
    }

    if (currentApplicantDocument) {
      const fileObj = currentApplicantDocument.file;

      const fileName = fileObj.comment
        ? `${fileObj.comment}.${fileObj.original_name.split('.').pop()}`
        : fileObj.original_name;

      return (
        <>
          {fileObj.id !== fileInEditMode?.id && (
            <a
              href={authLink(currentApplicantDocument.file.url)}
              className="text-primary"
              rel="noreferrer"
              target="_blank"
            >
              {isUserOperator ? fileName : fileObj.original_name}
            </a>
          )}

          {isUserOperator && fileObj.id !== fileInEditMode?.id && (
            <StyledEmptyButton
              className="ms-1"
              onClick={() => handleOpenEditMode(currentApplicantDocument.file)}
            >
              <i className="bx bx-edit-alt" />
            </StyledEmptyButton>
          )}

          {isEditFilenameMode && fileInEditMode.id === fileObj.id && (
            <>
              <EditFilename
                defaultValue={fileName}
                handleCloseEdit={disableEditFilename}
                handleChangeFilename={handleChangeFilename}
                handleSaveFilename={handleSaveFilename}
                // disabled={isDisabledFields}
              />
            </>
          )}
        </>
      );
    }

    if (isDisabledFields) {
      const fileObj = file;

      const fileName = fileObj.comment
        ? `${fileObj.comment}.${fileObj.original_name.split('.').pop()}`
        : fileObj.original_name;

      return (
        <>
          {!isEditFilenameMode && (
            <a
              href={authLink(file?.url)}
              className="text-primary"
              rel="noreferrer"
              target="_blank"
            >
              {isUserOperator ? fileName : fileObj.original_name}
            </a>
          )}

          {isUserOperator && !isEditFilenameMode && (
            <StyledEmptyButton
              className="ms-1"
              onClick={() => handleOpenEditMode(file)}
            >
              <i className="bx bx-edit-alt" />
            </StyledEmptyButton>
          )}

          {isEditFilenameMode && (
            <EditFilename
              defaultValue={fileName}
              handleCloseEdit={disableEditFilename}
              handleChangeFilename={handleChangeFilename}
              handleSaveFilename={handleSaveFilename}
            />
          )}
        </>
      );
    }

    return '';
  };

  const getMultipleTypeFileDisplay = file => {
    // return (
    //   <a
    //     href={authLink(file?.profile_document?.url)}
    //     className="text-primary"
    //     rel="noreferrer"
    //     target="_blank"
    //   >
    //     {file.profile_document?.original_name}
    //   </a>
    // );
    if (file.profile_document) {
      const fileObj = file;

      const currentApplicantFiles = formik.values.applicants?.[index].files;

      const comment = currentApplicantFiles.find(
        ({ file }) =>
          file.applicant_document_id === fileObj.profile_document.id,
      )?.file.comment;

      const fileName = comment
        ? `${comment}.${fileObj.profile_document.original_name
            .split('.')
            .pop()}`
        : fileObj.profile_document.original_name;

      return (
        <>
          {fileObj.profile_document &&
            fileObj?.profile_document?.id !==
              fileInEditMode?.profile_document?.id && (
              <a
                href={authLink(file.profile_document.url)}
                className="text-primary"
                rel="noreferrer"
                target="_blank"
              >
                {/* {file.profile_document.original_name} */}
                {isUserOperator
                  ? fileName
                  : fileObj?.profile_document?.original_name}
              </a>
            )}

          {fileObj?.profile_document &&
            isUserOperator &&
            fileObj?.profile_document?.id !==
              fileInEditMode?.profile_document?.id && (
              <StyledEmptyButton
                className="ms-1"
                onClick={() => handleOpenEditMode(file)}
              >
                <i className="bx bx-edit-alt" />
              </StyledEmptyButton>
            )}

          {isEditFilenameMode &&
            fileInEditMode.profile_document?.id ===
              fileObj.profile_document.id && (
              <EditFilename
                defaultValue={fileName}
                handleCloseEdit={disableEditFilename}
                handleChangeFilename={handleChangeFilename}
                handleSaveFilename={handleSaveFilename}
              />
            )}
        </>
      );
    }
  };

  // const sortedDocuments = useMemo(
  //   () =>
  //     requiredDocuments.documents?.sort(
  //       (a, b) => !!b.profile_document - !!a.profile_document,
  //     ),
  //   [requiredDocuments.documents],
  // );

  return (
    <div className="mt-4">
      <Row>
        <Col xs={12}>
          {notFound(
            'order.documentsNotFound',
            requiredDocuments.documents?.length === 0,
          )}

          {Loader && (
            <div className="w-100 my-5 d-flex align-items-center justify-content-center">
              {Loader}
            </div>
          )}

          {!Loader && requiredDocuments.documents?.length !== 0 && (
            <div className="table-responsive">
              <StyledTable className="mt-3">
                <thead>
                  <tr>
                    <th>{t('order.documentType')}</th>

                    <th>{t('order.documentName')}</th>

                    <th />
                    {isUserOperator && <th />}
                  </tr>
                </thead>

                <tbody>
                  {/* Documents with Visum IO integration */}
                  {/* {sortedDocuments */}
                  {requiredDocuments.documents
                    ?.filter(
                      document => document?.visum_integration?.document_type,
                    )
                    .map(document => (
                      <tr
                        key={
                          document.options?.ALLOW_MULTIPLE
                            ? `${document.id}-${document.profile_document?.id}`
                            : document.id
                        }
                      >
                        <td className="col-6">
                          <Label className="m-0 fs-14 fw-500">
                            {document.name?.[i18n.language]}

                            <StyledEmptyButton
                              $isNoFocus
                              id={`document-detail-${document.id}`}
                              className="ms-2"
                            >
                              <>
                                <i
                                  onClick={() => {
                                    handleOpenModal(document);
                                    // force user to save order in case visum form values were updated
                                    formik.setFieldValue(
                                      `applicants[${index}].dummyField`,
                                      null,
                                    );
                                  }}
                                  id={`document-detail-tooltip-${document.id}`}
                                  className="bx bx-question-mark fs-14"
                                />
                                <UncontrolledTooltip
                                  target={`document-detail-tooltip-${document.id}`}
                                >
                                  {t('document.details')}
                                </UncontrolledTooltip>
                              </>
                            </StyledEmptyButton>
                          </Label>
                        </td>

                        <td className="col-6">
                          {formik.values.applicants[index]
                            .visumIntegrationFillingStatus === 'FILLED'
                            ? 'Form is completed'
                            : 'Please complete the form!'}
                        </td>

                        <td>
                          {!isDisabledFields &&
                            document.visum_integration?.document_type && (
                              <StyledUpload
                                className="text-nowrap w-100"
                                onClick={() => {
                                  handleOpenModal(document);
                                  // force user to save order in case visum form values were updated
                                  formik.setFieldValue(
                                    `applicants[${index}].dummyField`,
                                    null,
                                  );
                                }}
                              >
                                <i className="bx bx-edit me-2" />

                                {/* <input {...getInputProps()} /> */}

                                {formik.values.applicants[index]
                                  .visumIntegrationFillingStatus === 'FILLED'
                                  ? t('order.viewQuestionnaire')
                                  : t('order.fillQuestionnaire')}
                              </StyledUpload>
                            )}
                        </td>
                      </tr>
                    ))}

                  {/* Documents with NO Visum IO integration */}

                  {/* {sortedDocuments */}
                  {requiredDocuments.documents
                    ?.filter(
                      document => !document.visum_integration?.document_type,
                    )
                    .map(document => (
                      <tr
                        key={
                          document.options?.ALLOW_MULTIPLE
                            ? `${document.id}-${document.profile_document?.id}`
                            : document.id
                        }
                      >
                        <td>
                          <Label className="m-0 fs-14 fw-500">
                            {document.name?.[i18n.language]}

                            <StyledEmptyButton
                              $isNoFocus
                              id={`document-detail-${document.id}`}
                              className="ms-2"
                            >
                              {(document?.description?.description_requirements[
                                i18n.language
                              ] ||
                                document?.description?.description_source[
                                  i18n.language
                                ] ||
                                document?.description?.description_title[
                                  i18n.language
                                ] ||
                                document?.example ||
                                document.questionnaire) && (
                                <>
                                  <i
                                    onClick={() => handleOpenModal(document)}
                                    id={`document-detail-tooltip-${document.id}`}
                                    className="bx bx-question-mark fs-14"
                                  />
                                  <UncontrolledTooltip
                                    target={`document-detail-tooltip-${document.id}`}
                                  >
                                    {t('document.details')}
                                  </UncontrolledTooltip>
                                </>
                              )}
                            </StyledEmptyButton>
                          </Label>
                        </td>

                        <td>
                          <StyledFileName className="d-flex">
                            {document.options?.ALLOW_MULTIPLE
                              ? getMultipleTypeFileDisplay(document)
                              : getCurrentFileDisplay(document)}
                          </StyledFileName>
                        </td>

                        <td>
                          {!isDisabledFields &&
                            (!document.options?.ALLOW_MULTIPLE ||
                              (document.options?.ALLOW_MULTIPLE &&
                                !document.profile_document)) && (
                              <>
                                {/* <StyledUpload
                                {...getRootProps()}
                                className="text-nowrap w-100"
                                onClick={() => {
                                  open();
                                  setSelectedFile(document);
                                }}
                              >
                                <i className="bx bx-upload me-2" />

                                <input {...getInputProps()} />

                                {t(
                                  document.profile_document
                                    ? 'order.replace'
                                    : 'order.uploadFile',
                                )}
                              </StyledUpload> */}

                                <StyledDropzone document={document} />
                              </>
                            )}
                        </td>
                        {isUserOperator && (
                          <td>
                            {document?.profile_document &&
                            document.options?.ALLOW_MULTIPLE ? (
                              <i
                                onClick={() =>
                                  setIsOpenDocumentDelete(
                                    document?.profile_document?.id,
                                  )
                                }
                                className="bx bxs-trash-alt"
                              />
                            ) : (
                              ''
                            )}
                          </td>
                        )}
                      </tr>
                    ))}

                  {isOpenDocumentDelete && !Loader && (
                    <ModalConfirmDelete
                      isOpenModal={isOpenDocumentDelete}
                      textHeader="documents.delete"
                      textBody="documents.deleteConfirm"
                      handleCloseModal={handleCloseModalDelete}
                      handleDelete={() =>
                        handleDocumentDelete(isOpenDocumentDelete)
                      }
                    />
                  )}

                  {isOpen && !Loader && (
                    <DocumentDetailModal
                      handleCloseModal={handleCloseModal}
                      isOpenModal={isOpen}
                      document={isOpen}
                      visumIntegrationAccessToken={
                        formik.values.applicants[index]
                          .visumIntegrationAccessToken
                      }
                      applicantIndex={index}
                    />
                  )}
                </tbody>
              </StyledTable>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
